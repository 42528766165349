html {
	min-height: 100%;
}

body {        
	font: 100%/1.4 Verdana, Arial, Helvetica, sans-serif;
	background-image: linear-gradient(to bottom, #BCD97F 0%, #EBF1C2 42%, #EBF1C2 100%);
}

h1, h2, h3, h4, h5, h6 {
	color: rgba(255,255,255,1);
	font-family: "Permanent Marker", cursive;
	text-shadow: -5px 4px 5px black;
}

a img {
	border: none;
	vertical-align: text-top;
}
a:link {
	color: #99cc66;
	text-decoration: underline;
}
a:visited {
	color: #009966;
	text-decoration: underline;
}
a:hover, a:active, a:focus {
	text-decoration: none;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-6-7 1:33:44
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-right
 * ----------------------------------------
 */
 @-webkit-keyframes scale-in-right {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
      opacity: 1;
    }
  }
  @keyframes scale-in-right {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
      opacity: 1;
    }
  }
  
/* ----------------------------------------------
 * Generated by Animista on 2020-6-7 1:42:55
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
 @-webkit-keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  .scale-in-right {
	-webkit-animation: scale-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.scale-in-center {
	-webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}